/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../store';

export interface MultiSelectState {
    checked: string[];
}

const initialState: MultiSelectState = {
    checked: [],
};

export const multiSelectSlice = createSlice({
    name: 'multiSelect',
    initialState,
    reducers: {
        toggleChecked: (state: MultiSelectState, action: PayloadAction<string>) => {
            const index = state.checked.indexOf(action.payload);
            if (index === -1) {
                state.checked.push(action.payload);
            } else {
                state.checked.splice(index, 1);
            }
        },
        checkAll: (state: MultiSelectState, action: PayloadAction<string[]>) => {
            state.checked = action.payload;
        },
    },
});

const multiSelectState = (state: RootState) => state.multiSelect;

export const checkedSelection: any = createSelector(
    [multiSelectState],
    (state: MultiSelectState) => state.checked,
);

export const isItemChecked = (id: string) =>
    createSelector([checkedSelection], (checked: string[]) => checked.includes(id));

export const { toggleChecked, checkAll } = multiSelectSlice.actions;
