import type { ListedObject } from '@api/goose/dist/enhancedGooseClient';
import { useListObjectsQuery } from '@api/goose/dist/enhancedGooseClient';
import { ErrorScreen } from '@local/svgs/dist/pageState/ErrorScreen';
import { SearchSvg } from '@local/svgs/dist/svg';
import { NotFoundSvg } from '@local/svgs/dist/svg/NotFoundSvg';
import { Delete } from '@local/web-design-system-2';
import EmptyState from '@local/web-design-system-2/dist/components/EmptyState/EmptyState';
import type { FieldDefinition } from '@local/web-design-system-2/dist/components/GenericListing/types';
import type { HeadCell } from '@local/web-design-system-2/dist/components/SortedList/SortedList';
import { SortedList } from '@local/web-design-system-2/dist/components/SortedList/SortedList';
import TableSkeleton from '@local/web-design-system-2/dist/components/TableSkeleton/TableSkeleton';
import ArrowDown from '@local/web-design-system-2/dist/icons/ArrowDown';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { hasRoleOrHigher } from '@local/workspaces/dist/utils/permissions';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { makeStyles } from 'tss-react/mui';

import { MessageSnackBar } from 'src/components/messageSnackBar/MessageSnackBar';
import { PaginatedList } from 'src/components/paginatedList/PaginatedList';
import { CheckedItemsToolbar } from 'src/components/toolBar/CheckedItemsToolbar';
import { useWorkspaceContext } from 'src/contexts/WorkspaceContext';
import { useObjectFilterParams } from 'src/hooks/useObjectFilterParams';
import { useObjectSearchParams } from 'src/hooks/useObjectSearchParams';
import { useObjectSortParams } from 'src/hooks/useObjectSortParams';
import { usePagination } from 'src/hooks/usePagination';
import { useRecycleItemHandler } from 'src/hooks/useRecycleItemHandler';
import { workspaceContentModeSelector } from 'src/store/features/displaySettingSlice';
import { checkedSelection, checkAll } from 'src/store/features/multiSelectSlice';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import {
    NETWORK_ERROR_DESCR,
    NETWORK_ERROR_TITLE,
    WORKSPACE_CONTENT_NO_CONTENT,
    WORKSPACE_CONTENT_NO_CONTENT_DESC,
    NO_OBJECTS_FOUND,
    NO_SEARCH_RESULTS_DESC,
} from 'src/strings';
import { Order } from 'src/types/fieldDefinition';
import { ItemType } from 'src/types/ItemTypes';
import { DEFAULT_PAGE_SIZE_OPTIONS } from 'src/utils/pagination';

import { ContentOptions } from '../ContentOptions';
import { objectDefinition } from './FieldRowDefinitions';
import { ObjectRow } from './ObjectRow';
import { TableHeader } from './TableHeader';
import type { ListedObjectDisplay } from './utils';
import { processGooseContents } from './utils';

const useStyles = makeStyles()(() => ({
    pagination: {
        padding: 0,
    },
    table: {
        display: 'flex',
        height: '71vh',
        flexGrow: 1,
    },
}));

interface ObjectListProps {
    objects: ListedObject[];
    isLoading: boolean;
    handleSort: (values: { key: string; order: Order }) => void;
    fields?: FieldDefinition[];
}

function ObjectList(props: ObjectListProps) {
    const { isLoading, objects, handleSort, fields = objectDefinition } = props;
    const { objectName } = useObjectSearchParams();
    const theme = useTheme();

    const dispatch = useAppDispatch();

    const checkedList = useAppSelector(checkedSelection) as string[];

    const { ConfirmRecycleDialog, setRecycleDialogOpen, isRecycleItemHandlerLoading } =
        useRecycleItemHandler();

    const { workspaceUserRole } = useWorkspaceContext();
    const isDisabled = !hasRoleOrHigher(workspaceUserRole ?? null, 'editor');

    const headCells: HeadCell<ListedObjectDisplay>[] = [
        ...fields.map((field) => ({
            id: field.key as keyof ListedObjectDisplay,
            label: field.label,
            sortable: !!field.sortFunction,
            sx: field.sx,
        })),
        {
            id: 'extraHeader' as keyof ListedObjectDisplay,
            label: '',
            sortable: false,
            sx: {
                width: '5%',
            },
        },
    ];

    useEffect(
        () => () => {
            dispatch(checkAll([]));
        },
        [],
    );

    const processedObjects = processGooseContents(0, objects);

    const renderRow = (object: ListedObjectDisplay) => (
        <ObjectRow key={object.object_id} object={object} enableCheckbox />
    );

    return (
        <>
            <MessageSnackBar />
            <SortedList
                data={processedObjects}
                headCells={headCells}
                renderRow={renderRow}
                isLoading={isLoading || isRecycleItemHandlerLoading}
                isApiSort
                pagination={false} // pagination controls handled elsewhere on the page
                EmptyComponent={
                    <Grid container flexGrow={1} alignItems="center" justifyContent="center">
                        <EmptyState
                            title={objectName ? NO_OBJECTS_FOUND : WORKSPACE_CONTENT_NO_CONTENT}
                            titleSx={{ py: 1 }}
                            message={
                                objectName
                                    ? NO_SEARCH_RESULTS_DESC
                                    : WORKSPACE_CONTENT_NO_CONTENT_DESC
                            }
                            messageSx={{ color: theme.palette.grey[700], py: 1 }}
                            image={objectName ? <SearchSvg /> : <NotFoundSvg />}
                            sx={{
                                paddingBottom: theme.spacing(14),
                                width: '50%',
                                margin: 'auto',
                            }}
                        />
                        <MessageSnackBar />
                    </Grid>
                }
                LoadingComponent={
                    <PaginatedList
                        header={<TableHeader fields={fields} hasActions hasCheckbox />}
                        content={<TableSkeleton rows={4} columns={headCells.length} hasAction />}
                    />
                }
                onSortChange={(key, newOrder) => {
                    dispatch(checkAll([]));
                    handleSort({
                        key: key as string,
                        order: newOrder === 'asc' ? Order.ASCENDING : Order.DESCENDING,
                    });
                }}
                defaultOrderBy={
                    fields.find((f) => f.defaultSortSettings)?.key as keyof ListedObjectDisplay
                }
                defaultOrder={
                    fields.find((f) => f.defaultSortSettings)?.defaultSortSettings?.order ===
                    Order.ASCENDING
                        ? 'asc'
                        : 'desc'
                }
                containerSx={{
                    table: { tableLayout: 'fixed' },
                    display: 'flex',
                    flexDirection: 'column',
                }}
                multiselect
                ToolbarComponent={CheckedItemsToolbar({
                    dispatch,
                    numChecked: checkedList.length,
                    actions: [
                        {
                            key: 'delete',
                            action: (
                                <Tooltip title="Delete">
                                    <IconButton
                                        onClick={() => setRecycleDialogOpen(true)}
                                        disabled={isDisabled}
                                        automation-id="toolbar-delete-button"
                                        color="inherit"
                                    >
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            ),
                        },
                    ],
                })}
            />
            <ConfirmRecycleDialog
                itemName={undefined}
                itemIds={checkedList}
                itemType={ItemType.OBJECT}
            />
        </>
    );
}

export const WorkspaceContent = () => {
    const { classes } = useStyles();
    const { evouiAffinityFolders } = useFlags();
    const workspaceContentMode = useAppSelector(workspaceContentModeSelector);
    const showFilterDropdown = evouiAffinityFolders && workspaceContentMode === 'path';
    const { page, pageSize, handleSetPage, handlePageSizeChange, setPaginationParams } =
        usePagination();

    const params = useParams();
    const sortFromHook = useObjectSortParams();
    const { filters: filtersFromHook } = useObjectFilterParams();
    const filters = useMemo(
        () => (showFilterDropdown ? filtersFromHook : {}),
        [showFilterDropdown, filtersFromHook],
    );
    const { updateObjectSortParams, orderBy, order, key } = useMemo(
        () =>
            showFilterDropdown
                ? sortFromHook
                : {
                      updateObjectSortParams: () => {},
                      orderBy: undefined,
                      order: undefined,
                      key: undefined,
                  },
        [showFilterDropdown, sortFromHook],
    );

    const { objectName } = useObjectSearchParams();

    const fields = objectDefinition.map((field) =>
        field.key === key ? { ...field, defaultSortSettings: { order: order as Order } } : field,
    );

    const handleSort = (values: { key: string; order: Order }) => {
        if (showFilterDropdown) {
            if (order === values.order && key === values.key) {
                return;
            }

            updateObjectSortParams(values);
            setPaginationParams((currentParams) => ({ ...currentParams, page: 0 }));
        }
    };

    useEffect(() => {
        setPaginationParams((currentParams) => ({ ...currentParams, page: 0 }));
    }, [filters, objectName]);

    const {
        data: gooseData,
        isFetching,
        isError,
    } = useListObjectsQuery({
        orgId: getOrgUuidFromParams(params),
        workspaceId: getSelectedWorkspaceFromParams(params),
        ...filters,
        objectName,
        orderBy,
        offset: page * pageSize,
        limit: pageSize,
    });

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        handleSetPage(newPage);
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const newPageSize = parseInt(event.target.value, 10);
        if (!Number.isNaN(newPageSize)) {
            handlePageSizeChange(newPageSize);
        }
    };

    if (isError) {
        return <ErrorScreen msg={NETWORK_ERROR_TITLE} details={NETWORK_ERROR_DESCR} />;
    }

    return (
        <Stack sx={{ width: '100%', height: '100%', overflowY: 'auto' }}>
            <ContentOptions
                paginationControls={
                    <Grid item>
                        <TablePagination
                            component="div"
                            automation-id="pagination-menu"
                            count={gooseData?.total ?? 0}
                            page={page}
                            labelRowsPerPage="Show"
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            onPageChange={handleChangePage}
                            rowsPerPage={pageSize}
                            rowsPerPageOptions={DEFAULT_PAGE_SIZE_OPTIONS}
                            classes={{ toolbar: classes.pagination }}
                            slotProps={{
                                select: { IconComponent: ArrowDown },
                            }}
                            sx={{
                                fontSize: '10px',
                                /* override MUI css style */
                                '& .MuiTablePagination-displayedRows': {
                                    fontSize: '10px',
                                },
                            }}
                        />
                    </Grid>
                }
            />
            <div className={classNames([classes.table])}>
                <ObjectList
                    objects={gooseData?.objects ?? []}
                    isLoading={isFetching}
                    handleSort={handleSort}
                    fields={fields}
                />
            </div>
        </Stack>
    );
};
