import { useEnhancedUpdateObjectByIdMutation } from '@api/goose/dist/enhancedGooseClient';
import { trackError } from '@local/metrics';
import OverflowTooltip from '@local/web-design-system-2/dist/components/OverflowTooltip/OverflowTooltip';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { hasRoleOrHigher } from '@local/workspaces/dist/utils/permissions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { useUpdateFileByIdMutation } from 'src/apiClients/GENERATED_fileClientEndpoints';
import { useSearchParamsContext } from 'src/contexts/SearchParamsContext';
import { useSnackBarContext } from 'src/contexts/SnackBarContext';
import { useWorkspaceContext } from 'src/contexts/WorkspaceContext';
import { checkAll } from 'src/store/features/multiSelectSlice';
import { useAppDispatch } from 'src/store/store';
import {
    MULTI_RESTORE_OBJECT_DIALOG_TITLE,
    MULTI_RESTORE_DIALOG_CONTENT,
    MULTI_RESTORE_OBJECT_DIALOG_WARNING,
    MULTI_RESTORE_DIALOG_CANCEL,
    MULTI_RESTORE_DIALOG_CONFIRM,
    MULTI_RESTORE_OBJECT_TOAST_SUCCESS,
    MULTI_VIEW_OBJECTS,
    RESTORE_OBJECT_DIALOG_TITLE,
    RESTORE_DIALOG_CONTENT,
    RESTORE_OBJECT_DIALOG_WARNING,
    RESTORE_DIALOG_CANCEL,
    RESTORE_DIALOG_CONFIRM,
    RESTORE_OBJECT_TOAST_SUCCESS,
    VIEW_RESTORED_OBJECT,
    MULTI_RESTORE_FILES_DIALOG_TITLE,
    MULTI_RECYCLE_DIALOG_CONTENT,
    MULTI_RECYCLE_DIALOG_WARNING,
    MULTI_RECYCLE_DIALOG_CANCEL,
    MULTI_RECYCLE_DIALOG_CONFIRM,
    MULTI_RESTORE_FILE_TOAST_SUCCESS,
    MULTI_VIEW_FILES,
    RESTORE_FILE_DIALOG_TITLE,
    RESTORE_FILE_TOAST_SUCCESS,
    VIEW_RESTORED_FILE,
    TRY_AGAIN,
    FAILED_TO_RESTORE,
} from 'src/strings';
import { ItemType } from 'src/types/ItemTypes';
import { formatObjectName } from 'src/utils/objectUtils';

interface SnackbarFailureActionsProps {
    itemIds: string[];
    itemType: ItemType;
    messages: {
        restoreSuccess: string;
        restoreFailure: string;
        viewRestoredItems: string;
        tryAgain: string;
    };
}

interface SnackbarSuccessActionsProps {
    itemType: ItemType;
    messages: {
        restoreSuccess: string;
        restoreFailure: string;
        viewRestoredItems: string;
        tryAgain: string;
    };
}
interface OnRestoreProps {
    confirm: boolean | null;
    itemIds: string[] | undefined;
    itemType: ItemType;
    messages: {
        restoreSuccess: string;
        restoreFailure: string;
        viewRestoredItems: string;
        tryAgain: string;
    };
}

interface ConfirmRestoreDialogProps {
    itemName: string | undefined;
    itemIds: string[];
    itemType: ItemType;
}

export const useRestoreItemHandler = () => {
    const [restoreDialogOpen, setRestoreDialogOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParamsContext();

    const [restoreObject] = useEnhancedUpdateObjectByIdMutation();
    const [restoreFile] = useUpdateFileByIdMutation();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const params = useParams();
    const orgId = getOrgUuidFromParams(params);
    const workspaceId = getSelectedWorkspaceFromParams(params);

    const { workspaceUserRole } = useWorkspaceContext();
    const isDisabled = !hasRoleOrHigher(workspaceUserRole ?? null, 'editor');

    const [isLoading, setIsLoading] = useState(false);

    const { setSnackBar } = useSnackBarContext();

    function SnackbarFailureActions({ itemIds, itemType, messages }: SnackbarFailureActionsProps) {
        return (
            <Button
                color="secondary"
                size="small"
                onClick={() => handleRestore({ confirm: true, itemIds, itemType, messages })}
                sx={{ whiteSpace: 'nowrap', textDecoration: 'underline' }}
            >
                {messages.tryAgain}
            </Button>
        );
    }

    function SnackbarSuccessActions({ itemType, messages }: SnackbarSuccessActionsProps) {
        return (
            <Button
                color="secondary"
                size="small"
                onClick={() => {
                    switch (itemType) {
                        case ItemType.OBJECT:
                            navigate('../overview');
                            break;
                        case ItemType.FILE:
                            navigate('../files');
                            break;
                        default:
                            break;
                    }
                }}
                sx={{ whiteSpace: 'nowrap', textDecoration: 'underline' }}
                automation-id={`notification-view-${itemType.toLowerCase()}`}
            >
                {messages.viewRestoredItems}
            </Button>
        );
    }

    const handleRestore = async ({ confirm, itemIds, itemType, messages }: OnRestoreProps) => {
        const failedItems: string[] = [];
        setRestoreDialogOpen(false);

        if (!confirm || !itemIds || isDisabled) {
            return;
        }

        setIsLoading(true);
        dispatch(checkAll([]));

        try {
            const promisesWithIds = itemIds.map((itemId) => {
                switch (itemType) {
                    case ItemType.OBJECT:
                        return {
                            id: itemId,
                            promise: restoreObject({
                                orgId,
                                workspaceId,
                                objectId: itemId,
                                deleted: false,
                                geoscienceObject: null,
                            }).unwrap(),
                        };
                    case ItemType.FILE:
                        return {
                            id: itemId,
                            promise: restoreFile({
                                organisationId: orgId,
                                workspaceId,
                                fileId: itemId,
                                deleted: false,
                            }).unwrap(),
                        };
                    default:
                        return {
                            id: '',
                            promise: Promise.resolve(),
                        };
                }
            });

            const results = await Promise.allSettled(promisesWithIds.map(({ promise }) => promise));

            results.forEach((result, index) => {
                if (result.status === 'rejected') {
                    failedItems.push(promisesWithIds[index].id);
                }
            });

            if (failedItems.length > 0) {
                throw new Error('Error restoring items');
            }

            setSnackBar(
                messages.restoreSuccess,
                'success',
                <SnackbarSuccessActions itemType={itemType} messages={messages} />,
            );

            searchParams.delete('id');
            setSearchParams(searchParams);
        } catch (error) {
            trackError(`Error restoring ${itemType.toLowerCase()}`, JSON.stringify(error));
            setSnackBar(
                `${messages.restoreFailure} ${failedItems.length} ${itemType.toLowerCase()}${
                    failedItems.length > 1 ? 's' : ''
                }`,
                'error',
                <SnackbarFailureActions
                    itemIds={failedItems}
                    itemType={itemType}
                    messages={messages}
                />,
            );
        }
        setIsLoading(false);
    };

    const getMessages = (itemType: ItemType, amount: 'single' | 'multi') => {
        const messageDictionary = {
            object: {
                single: {
                    dialogTitle: RESTORE_OBJECT_DIALOG_TITLE,
                    dialogContent: RESTORE_DIALOG_CONTENT,
                    dialogWarning: RESTORE_OBJECT_DIALOG_WARNING,
                    dialogCancel: RESTORE_DIALOG_CANCEL,
                    dialogConfirm: RESTORE_DIALOG_CONFIRM,
                    restoreSuccess: RESTORE_OBJECT_TOAST_SUCCESS,
                    restoreFailure: FAILED_TO_RESTORE,
                    viewRestoredItems: VIEW_RESTORED_OBJECT,
                    tryAgain: TRY_AGAIN,
                },
                multi: {
                    dialogTitle: MULTI_RESTORE_OBJECT_DIALOG_TITLE,
                    dialogContent: MULTI_RESTORE_DIALOG_CONTENT,
                    dialogWarning: MULTI_RESTORE_OBJECT_DIALOG_WARNING,
                    dialogCancel: MULTI_RESTORE_DIALOG_CANCEL,
                    dialogConfirm: MULTI_RESTORE_DIALOG_CONFIRM,
                    restoreSuccess: MULTI_RESTORE_OBJECT_TOAST_SUCCESS,
                    restoreFailure: FAILED_TO_RESTORE,
                    viewRestoredItems: MULTI_VIEW_OBJECTS,
                    tryAgain: TRY_AGAIN,
                },
            },
            file: {
                single: {
                    dialogTitle: RESTORE_FILE_DIALOG_TITLE,
                    dialogContent: RESTORE_DIALOG_CONTENT,
                    dialogWarning: RESTORE_OBJECT_DIALOG_WARNING,
                    dialogCancel: RESTORE_DIALOG_CANCEL,
                    dialogConfirm: RESTORE_DIALOG_CONFIRM,
                    restoreSuccess: RESTORE_FILE_TOAST_SUCCESS,
                    restoreFailure: FAILED_TO_RESTORE,
                    viewRestoredItems: VIEW_RESTORED_FILE,
                    tryAgain: TRY_AGAIN,
                },
                multi: {
                    dialogTitle: MULTI_RESTORE_FILES_DIALOG_TITLE,
                    dialogContent: MULTI_RECYCLE_DIALOG_CONTENT,
                    dialogWarning: MULTI_RECYCLE_DIALOG_WARNING,
                    dialogCancel: MULTI_RECYCLE_DIALOG_CANCEL,
                    dialogConfirm: MULTI_RECYCLE_DIALOG_CONFIRM,
                    restoreSuccess: MULTI_RESTORE_FILE_TOAST_SUCCESS,
                    restoreFailure: FAILED_TO_RESTORE,
                    viewRestoredItems: MULTI_VIEW_FILES,
                    tryAgain: TRY_AGAIN,
                },
            },
        };

        return messageDictionary[itemType][amount];
    };

    function ConfirmRestoreDialog({ itemName, itemIds, itemType }: ConfirmRestoreDialogProps) {
        const messages = getMessages(itemType, itemIds.length > 1 ? 'multi' : 'single');

        let restoreString = `${messages.dialogContent}`;
        let restoreTitle = '';

        if (!itemName) {
            restoreString += ` ${itemIds.length} ${
                itemType.toLowerCase() + (itemIds.length > 1 ? 's' : '')
            }?`;
        } else if (itemType === ItemType.OBJECT && itemName) {
            restoreString += ` "${formatObjectName(itemName)}"?`;
            restoreTitle = `"${formatObjectName(itemName)}"?`;
        } else if (itemType === ItemType.FILE && itemName) {
            restoreString += ` "${itemName}"?`;
            restoreTitle = `"${itemName}"?`;
        }

        return (
            <Dialog
                open={restoreDialogOpen}
                onClose={() =>
                    handleRestore({ confirm: false, itemIds: undefined, itemType, messages })
                }
                sx={{ maxWidth: '444px', margin: 'auto' }}
            >
                <DialogTitle automation-id="dialog-title">{messages.dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText automation-id="dialog-description">
                        <OverflowTooltip title={restoreTitle} maxLines={2}>
                            {restoreString}
                        </OverflowTooltip>
                        {messages.dialogWarning}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ padding: 2 }}>
                    <Button
                        autoFocus
                        color="secondary"
                        variant="outlined"
                        onClick={() =>
                            handleRestore({
                                confirm: false,
                                itemIds: undefined,
                                itemType,
                                messages,
                            })
                        }
                        automation-id="dialog-cancel-button"
                    >
                        {messages.dialogCancel}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() =>
                            handleRestore({ confirm: true, itemIds, itemType, messages })
                        }
                        automation-id="dialog-confirm-button"
                    >
                        {messages.dialogConfirm}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return { ConfirmRestoreDialog, setRestoreDialogOpen, isRestoreItemHandlerLoading: isLoading };
};
